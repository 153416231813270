import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import Layout from './components/layout/Layout';
import { getCluster, getLocation } from './data/api';
import Container from './components/core/Container'
import Button from './components/core/Button'
import Polyline from './Polyline'
import { toGeoJsonFeatureCollection } from './data/cluster'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
import { UserStateContext } from './App';

import './SavedCluster.scss'

const SavedCluster = () => {
    const { user } = useContext(UserStateContext)
    const { id } = useParams()
    const history = useHistory()
    const [cluster, setCluster] = useState(null)
    const [clusterName, setClusterName] = useState('')
    const [colors, setColors] = useState({ lineColor: '#D6EBEB', backgroundColor: '#D6EBEB' })


    useEffect(() => {
        getCluster(id)
            .then(c => {
                setColors({
                    lineColor: c.line_color,
                    backgroundColor: c.background_color
                })
                return toGeoJsonFeatureCollection(c.cluster_activities)
            })
            .then(c => {
                setCluster(c)
                return c
            })
            .then(c => {
                console.log(c)
                const lat = c.features[0].geometry.coordinates[0][1]
                const lon = c.features[0].geometry.coordinates[0][0]
                return getLocation(lat, lon)
                    .then(name => setClusterName(name))
            })

    }, [])

    return (
        <Layout>
            <div className="wrapper-container">
                <div className="top-container">
                    <h1>{clusterName}</h1>
                </div>

                <Polyline
                    cluster={cluster}
                    animate={true}
                    strokeWidth="2"
                    box={{ width: 4000, height: 3000 }}
                    style={{ maxHeight: '75vh', padding: '0', color: colors.lineColor, backgroundColor: colors.backgroundColor }} />

                <div className="btn-container">
                    <Button className="to-home-button text-stylish " onClick={() => {
                        history.push("/")
                    }}>
                        {user?.token ? 'Back to Dashboard' : 'Try it yourself!'}
                    </Button>
                </div>
            </div>
        </Layout>
    );
};

export default SavedCluster;