import React from 'react';
import './button.scss'

export const IconButton = ({className, children, onClick, style }) => {
    return (
        <button className={"icon-button mapx-button " + className} onClick={onClick} style={style}>
            {children}
        </button>
    );
}


const Button = ({className, children, onClick, style }) => {
    return (
        <button className={"mapx-button " + className} onClick={onClick} style={style}>
            {children}
        </button>
    );
};

export default Button;