import React, { useContext } from 'react';
import ClusterManager from '../../ClusterManager';
import Container from '../core/Container';
import './settings.scss'
import { DashboardContext } from '../../Dashboard';
import { ContentContainer } from '../core/SettingsContainer'

const Settings = ({ style }) => {
    const { username } = useContext(DashboardContext)
    return (
        <Container style={{display: 'grid', ...style}}>
            <ContentContainer >
                <h2 className="welcome">Hello {username}!</h2>
            </ContentContainer>
            <ClusterManager />
        </Container>
    );
};

export default Settings;