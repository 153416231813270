import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { UserStateContext } from './App';
import { CircularProgress } from '@material-ui/core';
import Layout from './components/layout/Layout'
import { callback } from './data/api'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Callback = () => {
    const query = useQuery();
    const code = query.get('code')
    const scope = query.get('scope')
    const { dispatch } = useContext(UserStateContext)
    const history = useHistory()

    useEffect(() => {
        callback(code, scope)
            .then(userState => {
                dispatch({
                    type: 'ADD_USER_STATE',
                    payload: userState,
                })
                history.push("/");
            })
            .catch(err => {
                history.push("/");
            })
    }, [])

    return (
        <Layout >
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10em' }}>
                <div style={{ display: 'grid' }}>
                    <h1>Loading activities from strava...</h1>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default Callback;