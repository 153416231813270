import React, { useContext } from 'react';
import { DashboardContext } from '../../Dashboard'
import { Checkbox } from '@material-ui/core'


const AnimationFilter = () => {
    const { animate, setAnimate } = useContext(DashboardContext)
    return (
        <>
        <p style={{margin: 0}}>Activity animation</p>
            <div className="activity-filter-item" style={{ display: 'flex' }}>
                <Checkbox color="primary" checked={animate} onChange={(e) => {
                    setAnimate(e.target.checked)
                }} />
                <p>Animate</p>
            </div >
        </>
    );
};

export default AnimationFilter;