import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserStateContext } from '../../App';
import Button from '../core/Button';
import './header.scss';

const Header = ({ style }) => {
    const { user, dispatch } = useContext(UserStateContext)
    const history = useHistory()
    return (

        <div className="header" style={style}>
            <div className="logo-container" onClick={() => {
                history.push('/')
            }}>
                <h1>mapx</h1>
            </div>
            {!!user?.token &&
                <Button onClick={() => {
                    dispatch({
                        type: 'LOGOUT'
                    })
                }}>
                    Logout
            </Button>
            }
        </div>


    );
};

export default Header;