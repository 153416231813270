import React, { useState } from 'react';
import './settings-container.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Divider from './Divider';

export const ContentContainer = ({className, children, style, onClick }) => {
    return (
        <div>
            <div className={"settings-content-container " + className } onClick={onClick} style={style}>
                {children}
            </div>
            <Divider />
        </div>
    )
}

const SettingsContainer = ({ children, title, subtitle, style, icon }) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <div className="settings-container" style={style}>
            <div>
                <div className="settings-title-container" onClick={() => setExpanded(!expanded)}>
                    <div style={{display: 'flex', gap: '10px'}}>
                        {!!icon && icon}
                        <div>
                            <h3>{title}</h3>
                            {subtitle ? <p className="text-small">{subtitle}</p> : null}
                        </div>
                    </div>
                    {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </div>
            </div>
            <Divider />
            {expanded &&
                <ContentContainer>
                    {children}
                </ContentContainer>
            }

        </div>
    );
};

export default SettingsContainer;