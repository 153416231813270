import React from 'react';
import Header from './Header';
import Footer from './Footer'
import './layout.scss'

const Layout = ({ children, style }) => {
    return (
        <div className="layout-container" style={style}>
            <Header />
            <div className="content-wrapper">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;