import React, { useContext } from 'react';
import { UserStateContext } from './App';
import Dashboard from './Dashboard';
import Login from './Login';


const Home = () => {
    const { user } = useContext(UserStateContext)
    

    if (user.token !== undefined) {
        return (<Dashboard />)
    } else {
        return (<Login />)
    }

};

export default Home;