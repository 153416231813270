import React, { useState } from 'react';
import { Slider as MaterialSlider } from '@material-ui/core'

const Slider = ({ label, setFunction, max, defaultValue }) => {
    const [sliderValue, setSliderValue] = useState(defaultValue || 1)
    return (
        <div>
            <p style={{margin: 0}}>{label}</p>
            <MaterialSlider
                color="primary"
                valueLabelDisplay="auto"
                step={1}
                value={sliderValue}
                min={1}
                max={max || 20}
                marks
                onChange={(e, v) => {
                    setSliderValue(v)
                }}
                onChangeCommitted={(e, v) => {
                    setFunction(v)
                }}
            />
        </div>
    );
};

export default Slider;