import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core'
import { DashboardContext } from '../../Dashboard'
import { ClusterContext } from '../../ClusterManager'
import './activityfilter.scss'


const ActivityFilter = () => {
    const { activities } = useContext(DashboardContext)
    const { activityFilterList, setActivityFilterList } = useContext(ClusterContext)
    const [checked, setChecked] = useState([])

    useEffect(() => {
        let state = [...new Set(activities.map(activity => activity.type))]
            .map(activityType => { return { type: activityType, checked: !activityFilterList.includes(activityType) } })
        setChecked(state)
    }, [activities])


    if (activities?.length === 0) {
        return (<></>)
    }

    const onCheck = (i) => (event) => {
        const item = { ...checked[i], checked: !checked[i].checked }
        let checkedCopy = [...checked]
        checkedCopy[i] = item
        setChecked(checkedCopy)

        const activityFilterList = checkedCopy.filter(filter => !filter.checked)
            .map(filter => filter.type)
            setActivityFilterList(activityFilterList)
    }

    return (
        <div className="activity-filter-container">
            <p>Filter activities</p>
            {!!checked && checked.map((filter, i) => {
                return (
                    <div key={"filter-" + filter.type} className="activity-filter-item" style={{ display: 'flex' }}>
                        <Checkbox color="primary" value={filter.type} checked={filter.checked} onChange={onCheck(i)} />
                        <p>{filter.type}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default ActivityFilter;