import React, { useEffect, useRef } from 'react';
import { line, curveCatmullRom } from 'd3-shape'
import { geoMercator } from 'd3-geo'
import { select } from 'd3-selection'
import 'd3-transition';
import { easeLinear } from 'd3'


const Polyline = ({ animate, cluster, style, strokeWidth, box, className }) => {
    const ref = useRef()
    const padding = 50;
    const width = box?.width || 3000;
    const height = box?.height || 4000;

    useEffect(() => {
        select(ref.current).selectAll("path").remove()

        if (cluster === null || cluster === undefined) {
            return;
        }

        const projection = geoMercator()
            .fitExtent([[padding, padding], [width - padding * 2, height - padding * 2]], cluster);

        const curve_func = line()
            .x(d => d[0])
            .y(d => d[1])
            .curve(curveCatmullRom)

        let i = 0;
        cluster.features
            .map(feature => feature.geometry.coordinates)
            .forEach(coordinates => {
                const projectedCoordinates = coordinates.map(projection)

                const path = select(ref.current)
                    .append("path")
                    .attr("d", curve_func(projectedCoordinates))
                    .attr("stroke", style?.color || "black")
                    .attr("stroke-width", strokeWidth || 1)
                    .attr("fill", "none")
                    .attr("stroke-linecap", "round")
                    .attr("stroke-miterlimit", 4)

                if (animate) {
                    const length = path.node().getTotalLength()
                    path
                        .attr("stroke-dasharray", length + " " + length)
                        .attr("stroke-dashoffset", length)
                        .transition()
                        .delay(i * 100)
                        .duration(length * 2)
                        .attr("stroke-dashoffset", 0)
                        .ease(easeLinear)
                }


                i += 1
            });

    }, [cluster, animate, style?.color])

    return (
        <svg ref={ref} className={className} viewBox={"0 0 " + width + " " + height} preserveAspectRatio="xMidYMid meet" style={{ ...style, }}>
        </svg>
    );
};

export default Polyline;