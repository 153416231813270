import React, { useContext, useState } from 'react';
import { DashboardContext } from '../../Dashboard';
import ColorCircle from './ColorCircle';
import './ThemePicker.scss';

const themes = [
    { leftColor: "#a1ff82", rightColor: "#2C4251" },
    { leftColor: "black", rightColor: "white" },
    { leftColor: "white", rightColor: "black" },
    { leftColor: "white", rightColor: "#3B3561" },
    { leftColor: "#E4572E", rightColor: "#29335C" },
    { leftColor: "#261447", rightColor: "#F39C6B" },
    { leftColor: "#EFEFEF", rightColor: "#3454D1" },
    { leftColor: "#83858C", rightColor: "#EBEBD3" },
    { leftColor: "#00CC66", rightColor: "#2274A5" },
]

export const defaultTheme = themes[0];

const getIndex = (theme, themes) => {
    for (let i = 0; i < themes.length; i++) {
        if (themes[i].leftColor === theme.leftColor && themes[i].rightColor === theme.rightColor) {
            return i;
        }
    }
    return 0;
}

const ThemePicker = () => {
    const { theme, setTheme } = useContext(DashboardContext)
    const [selectedColorIndex, setSelectedColorIndex] = useState(getIndex(theme, themes))
    const [colors] = useState(themes)

    const onColorClick = (clickIndex) => {
        setSelectedColorIndex(clickIndex)
        setTheme(colors[clickIndex])
    }

    return (
        <div className="theme-picker-container" >
            {colors.map((color, i) => {
                return <ColorCircle
                    key={i}
                    leftColor={color.leftColor}
                    rightColor={color.rightColor}
                    selected={i == selectedColorIndex}
                    onClick={() => onColorClick(i)}
                />
            })}
        </div>
    );
};

export default ThemePicker;