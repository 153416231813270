import React, { useRef } from 'react';
import { select } from 'd3-selection'
import { arc } from 'd3'
import './ColorCircle.scss'
import { useEffect } from 'react';

const ColorCircle = ({ onClick, style, className, leftColor, rightColor, selected }) => {
    const ref = useRef()


    useEffect(() => {
        select(ref.current).selectAll("path").remove()

        select(ref.current)
            .append("circle")
            .attr("transform", "translate(25,25)")
            .attr("cy", 0)
            .attr("cx", 0)
            .attr("r", 23)
            .attr('stroke', '#D6EBEB')
            .attr('fill', '#D6EBEB')
            .attr('stroke-width', 1);

        select(ref.current)
            .append("path")
            .attr("transform", "translate(25,25)")
            .attr("d", arc()
                .innerRadius(0)
                .outerRadius(15)
                .startAngle(7.065)
                .endAngle(3.925)
            )
            .attr('stroke', leftColor)
            .attr('fill', leftColor);


        select(ref.current)
            .append("path")
            .attr("transform", "translate(25,25)")
            .attr("d", arc()
                .innerRadius(0)
                .outerRadius(15)
                .startAngle(0.785)
                .endAngle(3.925)
            )
            .attr('stroke', rightColor)
            .attr('fill', rightColor);

        if (selected == true) {
            select(ref.current)
                .append("circle")
                .attr("transform", "translate(25,25)")
                .attr("cy", 0)
                .attr("cx", 0)
                .attr("r", 22)
                .attr('stroke', '#2C4251')
                .attr('fill', 'transparent')
                .attr('stroke-width', 3);
        }
    }, [selected])

    return (
        <svg onClick={onClick} viewBox={"0 0 " + 50 + " " + 50} ref={ref} style={{ ...style, }} className={'color-circle ' + className}>
        </svg>
    );
};

export default ColorCircle;