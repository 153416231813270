import React from 'react';
import './container.scss'

const Container = ({children, style, className}) => {
    return (
        <div className={"mapx-container " + className} style={style}>
            {children}
        </div>
    );
};

export default Container;