import React, { useEffect, useState } from 'react';
import Container from './components/core/Container';
import Layout from './components/layout/Layout';
import { getRandomCluster, getRedirectUrl } from './data/api';
import { toGeoJsonFeatureCollection } from './data/cluster';
import { ReactComponent as StravaButton } from './images/strava_btn.svg';
import './login.scss';
import Polyline from './Polyline';
//import Map from './Map'


const Login = () => {
    const [redirectUrl, setRedirectUrl] = useState(null)




    useEffect(() => {
        getRedirectUrl()
            .then(url => setRedirectUrl(url))

    }, [])

    return (
        <Layout style={{ backgroundColor: '#2C4251', minHeight: '100vh' }}>
            <div className="login-container">



                <Container className="strava-login-container">
                    <h1>Login with Strava</h1>
                    <StravaButton color="primary" className="strava-btn" onClick={() => {
                        if (redirectUrl !== null) {
                            window.location.assign(redirectUrl)
                        }
                    }} />
                </Container>


            </div>
            <div className="custom-shape-divider-top-1605469539">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                </svg>
            </div>

        //
        </Layout>
    );
};

export default Login;
