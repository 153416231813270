import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShareIcon from '@material-ui/icons/Share';
import React from 'react';
import { useContext } from 'react';
import { ContentContainer } from '../core/SettingsContainer';
import './Share.scss'
import { ClusterContext } from '../../ClusterManager'
import { saveCluster } from '../../data/api'
import { UserStateContext } from '../../App'
import { DashboardContext    } from '../../Dashboard' 
import { useHistory } from 'react-router-dom'

const Share = () => {
    const { selectedCluster } = useContext(ClusterContext)
    const { theme } = useContext(DashboardContext)
    const { user } = useContext(UserStateContext)
    const history = useHistory()
    return (
        <ContentContainer className="share-container" onClick={() => {
            const ids = selectedCluster.features.map(feature => feature.properties.activityId)
            console.log(selectedCluster)
            saveCluster(user, ids, theme.leftColor, theme.rightColor)
                .then((resp) => history.push('/cluster/' + resp.id))
        }}>
            <div className="share-content-container">
                <div className="share-left-container" >
                    <ShareIcon />
                    <h3 style={{ margin: 0 }}>Create shareable link</h3>
                </div>
                <KeyboardArrowRightIcon />
            </div>
        </ContentContainer>
    );
};

export default Share;