import { CircularProgress } from '@material-ui/core';
import React, { useContext } from 'react';
import Polyline from '../../Polyline';
import { DashboardContext } from '../../Dashboard'
import Container from '../core/Container';
import './ClusterDisplay.scss'
//import Map from '../../Map';

const ClusterDisplay = ({ cluster }) => {
    const { animate, theme } = useContext(DashboardContext)
    if (cluster === null) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        //<Map interactive={true} cluster={cluster} />
         <Polyline className="polyline"  id={1} animate={animate} cluster={cluster} style={{ backgroundColor: theme?.rightColor, color: theme?.leftColor, height: '75vh', }} />
    );
};

export default ClusterDisplay;