import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserStateContext } from './App';
import Layout from './components/layout/Layout';
import ClusterDisplay from './components/render/ClusterDisplay';
import Settings from './components/settings/Settings';
import './dashboard.scss';
import { getActivities, getUsername } from './data/api';
import { defaultTheme } from './components/settings/ThemePicker'

export const DashboardContext = createContext()

const Dashboard = () => {
    const { user, dispatch } = useContext(UserStateContext)
    const [username, setUsername] = useState("");
    const [activities, setActivities] = useState([])
    const [clusters, setClusters] = useState([])
    const [animate, setAnimate] = useState(true)
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [theme, setTheme] = useState(defaultTheme)

    useEffect(() => {
        getActivities(user)
            .then(json => {
                console.log()
                setActivities(json)
            })
            .catch(err => {
                dispatch({
                    type: 'LOGOUT'
                })
            })
    }, [])

    useEffect(() => {
        getUsername(user)
            .then(name => {
                setUsername(name)
            })
            .catch(err => {
                dispatch({
                    type: 'LOGOUT'
                })
            })
    }, [])


    return (
        <DashboardContext.Provider value={{
            activities,
            setClusters,
            selectedCluster,
            setSelectedCluster,
            clusters,
            username,
            animate,
            setAnimate,
            theme,
            setTheme
        }}>
            <Layout>
                <div className="dashboard-content" >
                    <ClusterDisplay cluster={selectedCluster} />
                    <Settings />
                </div>
            </Layout>
        </DashboardContext.Provider>
    );
};

export default Dashboard;