

export const callback = async (code, scope) => {
    return fetch('/api/strava/callback?code=' + code + "&scope=" + scope, {
        method: 'GET',
    }).then(res => res.json())
}

export const getRedirectUrl = async () => {
    return fetch('/api/strava/redirect/url')
        .then(res => res.json())
        .then(json => json.url)
}

export const saveCluster = async (user, ids, lineColor, backgroundColor) => {

    const queryParams = ids.map(id => 'activityIds=' + id).join('&') + '&lineColor=' + encodeURIComponent(lineColor) + '&backgroundColor=' + encodeURIComponent(backgroundColor)
    
    console.log(queryParams)
    return fetch('/api/cluster?' + queryParams, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + user.token
        },
    }).then(response => response.json())
}

export const getRandomCluster = async () => {
    return fetch('/api/cluster/random')
        .then(res => res.json())
}

export const getCluster = async (clusterId) => {
    return fetch('/api/cluster/' + clusterId)
        .then(res => res.json())
}

export const getActivities = async (user) => {
    return fetch('/api/activities', {
        headers: {
            'Authorization': 'Bearer ' + user.token
        },
    }).then(res => {
        if (res.status == 401 || res.status == 400) {
            throw "Bad token"
        } else {
            return res.json()
        }
    })
}

export const getUsername = async (user) => {
    return fetch('/api/user', {
        headers: {
            'Authorization': 'Bearer ' + user.token
        },
    }).then(res => {
        if (res.status == 401 || res.status == 400) {
            throw "Bad token"
        } else {
            return res.text()
        }
    })
}

export const getLocation = async (lat, lon) => {
    return fetch('/api/location?lat=' + lat + '&lon=' + lon + '')
        .then(resp => resp.json())
        .then(json => json.name || '?')
}