import React, { createContext, useReducer } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Callback from './Callback'
import Home from './Home'
import { ThemeProvider, createTheme } from '@material-ui/core'
import SavedCluster from './SavedCluster'

export const UserStateContext = createContext()

function userStateReducer(state, action) {
  console.log(action)
  switch (action.type) {
    case 'ADD_USER_STATE':
      localStorage.setItem("user", JSON.stringify({ ...action.payload }))
      return { ...action.payload }
      case 'LOGOUT':
        localStorage.removeItem("user")
        return {}
    default:
      return state
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C4251',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f0f0f0',
    },
  },
});

function App() {
  const [user, dispatch] = useReducer(userStateReducer, JSON.parse(localStorage.getItem("user")) || {})

  return (
    <ThemeProvider theme={theme}>
      <UserStateContext.Provider value={{ user, dispatch }}>
        <Router>
          <Switch>
            <Route exact path='/callback' component={Callback} />
            <Route exact path='/' component={Home} />
            <Route exact path='/cluster/:id' component={SavedCluster} />
          </Switch>
        </Router>
      </UserStateContext.Provider >
    </ThemeProvider >
  );
}

export default App;
