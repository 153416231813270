import React from 'react';
import { ReactComponent as StravaCompatible } from '../../images/compatible_strava.svg';
import './footer.scss';


const Footer = () => {
    return (
        <footer className="footer-wrapper" >


            <StravaCompatible style={{ width: '100px', justifySelf: 'center', color: '#2C4251' }} />
        </footer>
    );
};

export default Footer;