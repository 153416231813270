import clustersDbscan from '@turf/clusters-dbscan';
import mapbox from '@mapbox/polyline';

export const toGeoJsonFeatureCollection = (activities) => {
    const features = activities.map(activity => toGeoJsonFeature(activity))

    return {
        type: "FeatureCollection",
        features: features,
    }
}

export const toGeoJsonClusterFeatureCollection = (activities) => {
    const features = activities.map(activity => toGeoJsonFeature(activity))

    const points = features
    .filter(f => f.geometry.coordinates[0] !== undefined)
    .map(feature => {

        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: feature.geometry.coordinates[0]
            },
            properties: {
                feature: feature,
            }
        }
    })

    return {
        type: "FeatureCollection",
        features: points,
    }
}

export const toGeoJsonFeature = (activity) => {
    const coordinates = mapbox.decode(activity.polyline)
        .map(c => [c[1], c[0]])

    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coordinates
        },
        properties: {
            activityId: activity.id
        }
    }
}

export async function clusterize(activities, clusterNodeDistance, nodesPerCluster) {
    const pointsGeoJson = toGeoJsonClusterFeatureCollection(activities)
    console.log(JSON.stringify(pointsGeoJson))
    const asdf = clustersDbscan(pointsGeoJson, clusterNodeDistance, { minPoints: nodesPerCluster, mutate: true, units: "kilometers" })

    const clustered = asdf
        .features
        .filter(point => point.properties.dbscan === "core")

    const grouped = groupBy(clustered, point => point.properties.cluster)

    let clusters = []
    for (let i = 0; i < grouped.size; i++) {
        const collection = grouped.get(i)
            .map(point => point.properties.feature)

        const geojson = {
            type: "FeatureCollection",
            features: collection,
            properties: {
                id: i,
            },
        }
        clusters.push(geojson)
    }
    return clusters
}

const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
