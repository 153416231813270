import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PaletteIcon from '@material-ui/icons/Palette';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IconButton } from './components/core/Button';
import SettingsContainer, { ContentContainer } from './components/core/SettingsContainer';
import Slider from './components/core/Slider';
import ActivityFilter from './components/render/ActivityFilter';
import AnimationFilter from './components/render/AnimationFilter';
import Share from './components/settings/Share';
import ThemePicker from './components/settings/ThemePicker';
import { DashboardContext } from './Dashboard';
import { clusterize } from './data/cluster';
import { getLocation } from './data/api'

export const ClusterContext = createContext()



const ClusterManager = ({ style }) => {
    const { clusters, setClusters, selectedCluster, setSelectedCluster, activities } = useContext(DashboardContext)
    const [clusterNodeDistance, setClusterNodeDistance] = useState(1)
    const [nodesPerCluster, setNodesPerCluster] = useState(8)
    const [clusterIndex, setClusterIndex] = useState(0)
    const [activityFilterList, setActivityFilterList] = useState([])
    const [clusterNames, setClusterNames] = useState([]);

    const fetchClusterNames = async (clusters) => {
        const names = await Promise.all(clusters.map((cluster) => {
            console.log(cluster.features[0].geometry)
            const lat = cluster.features[0].geometry.coordinates[0][1]
            const lon = cluster.features[0].geometry.coordinates[0][0]
            return getLocation(lat, lon)
        }))
        setClusterNames(names)
    }

    const setCluster = (index) => {
        setClusterIndex(index)
        setSelectedCluster(clusters[index])
    }

    useEffect(() => {
        fetchClusterNames(clusters)
    }, [clusters])

    useEffect(() => {
        const filteredActivities = activities.filter(activity => !activityFilterList.includes(activity.type))

        setSelectedCluster(null)
        setClusterIndex(0)
        clusterize(filteredActivities, clusterNodeDistance, nodesPerCluster)
            .then(clusters => {
                setClusters(clusters)
                setSelectedCluster(clusters[0])
                return clusters
            })

    }, [activities, activityFilterList, clusterNodeDistance, nodesPerCluster])

    return (
        <ClusterContext.Provider value={{
            activityFilterList,
            setActivityFilterList,
            selectedCluster
        }}>
            <div style={{ ...style, display: 'grid' }}>
                {clusters?.length ?
                    <div>
                        <ContentContainer>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <IconButton className="icon-button" onClick={() => {
                                    let newIndex = (clusterIndex === 0) ? clusters.length - 1 : clusterIndex - 1
                                    setCluster(newIndex)
                                }}>
                                    <KeyboardArrowLeftIcon />
                                </IconButton>
                                <div style={{ width: '200px', textAlign: 'center' }}>
                                    <h3 style={{ margin: 0 }}>{clusterNames[clusterIndex] || '?'}</h3>
                                    <p style={{ margin: 0 }}>{clusterIndex + 1} of {clusters.length}</p>
                                </div>
                                <IconButton onClick={() => {
                                    let newIndex = (clusterIndex === clusters.length - 1) ? 0 : clusterIndex + 1;
                                    setCluster(newIndex)
                                }}>
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </div>

                        </ContentContainer>

                    </div>
                    : null}

                {clusters?.length ?
                    <Share />
                    : null}





                <SettingsContainer title="Settings" icon={<SettingsIcon />}>
                    <AnimationFilter />
                    <ActivityFilter />

                    <Slider
                        label="Cluster activity distance (km)"
                        setFunction={setClusterNodeDistance}
                        defaultValue={clusterNodeDistance}
                    />
                    <Slider
                        label="Activities per cluster"
                        setFunction={setNodesPerCluster}
                        defaultValue={nodesPerCluster}
                    />
                </SettingsContainer>
                <SettingsContainer title="Theme" icon={<PaletteIcon />}>
                    <ThemePicker />
                </SettingsContainer>

            </div >





        </ClusterContext.Provider>

    )
}

export default ClusterManager;
